<template>
  <b-container id="app">
    <nav-bar />
    <banner-content />
    <hr/>
    <about />
    <hr/>
    <contact />
  </b-container>
</template>

<script>
import NavBar from './components/NavBar.vue'
import BannerContent from './components/BannerContent.vue'
import About from './components/About.vue'
import Contact from './components/Contact.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    BannerContent,
    About,
    Contact
  }
}
</script>

<style>
body {
  background: #cdd9de !important;
}

textarea, input {
  background: #cdd9de !important;
  border: thin solid #2c3e50 !important;
  font-weight: bold;
}

.button {
  background: #2c3e50;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1 {
  font-size: 3pc;
}

h2 {
  font-size: 2.2pc;
}

p, label {
  font-size: 1.4pc;
}

small {
  font-size: .9pc;
}

.link {
  font-size: 1.2pc;
}
</style>
