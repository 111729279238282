<template>
  <b-row>
    <b-col class="text-left" cols="12" md="6">
      <h1 class="font-weight-bold">
        <b-img
          fluid
          src="../assets/Brand-w.shadow.png"
          style="max-height: 75px;"
        />
      </h1>
    </b-col>

    <b-col class="text-right py-3 pl-4 pr-3" cols="12" md="6">
      <b-link class="link px-4" href="#about">our services</b-link>
      <b-link class="link" href="#contact">contact us</b-link>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName]
      console.log(element)
      var top = element.offsetTop

      window.scrollTo(0, top)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: #113a4b;
}
h3 {
  margin: 40px 0 0;
}
h5 {
  font-weight: bold;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #113a4b;
  text-decoration: none;
}
a:hover {
  color: #47585f;
}
.link {
  color: #113a4b;
  font-size: 1.3pc;
}
</style>
