<template>
  <b-row>
    <b-col class="my-5">
      <b-carousel
        :interval="4000"
        fade
        indicators
        no-hover-pause
        background="#fff"
        style="text-shadow: 2px 2px 2px #333;"
        class="shadow"
      >
        <!-- Text slides with image -->
        <b-carousel-slide
          caption="IT Consulting in the GTA"
          text="We provide services to the GTA and neighbouring regions"
          img-src="@/assets/banner/toronto.jpg"
          style="text-shadow: 2px 2px 2px #333;"
        ></b-carousel-slide>

        <b-carousel-slide
          caption="Office IT services"
          text="Finding the optimal technology solutions for your needs"
          img-src="@/assets/banner/computers.png"
          style="text-shadow: 2px 2px 2px #333;"
        ></b-carousel-slide>

        <b-carousel-slide
          caption="Network services"
          text="Network solutions designed specifically for your organization"
          img-src="@/assets/banner/server-rack.jpg"
          style="text-shadow: 2px 2px 2px #333;"
        ></b-carousel-slide>

        <b-carousel-slide
          caption="Telecom services"
          text="Integrated phone and communication services to keep clients connected"
          img-src="@/assets/banner/telecom.jpg"
          style="text-shadow: 2px 2px 2px #333;"
        ></b-carousel-slide>
      </b-carousel>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BannerContent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: #1e6787;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.link {
  color: #1e6787;
}
.carousel-item:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}
</style>
