<template>
  <div id="about" class="mb-5">
    <h1 class="mt-5">what we do</h1>
    <b-row v-for="(item, index) in aboutList" :key="index" class="text-left">
      <!-- <b-col v-if="!isOdd(index)" class="text-center p-5">
        <b-icon :icon="item.icon" style="width: 120px; height: 120px;"></b-icon>
      </b-col> -->

      <b-col v-if="!isOdd(index)" />
      <b-col class="mt-3" cols="6">
        <h2><b-icon :icon="item.icon"></b-icon> {{ item.title }}</h2>
        <p>{{ item.description }}</p>
      </b-col>
      <b-col v-if="isOdd(index)" />

      <!-- <b-col v-if="isOdd(index)" class="text-center p-5">
        <b-icon :icon="item.icon" style="width: 120px; height: 120px;"></b-icon>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Homepage',
  data() {
    return {
      aboutList: [
        {
          title: 'IT Management',
          description:
            'With the correct tools precisely for your needs, your team will experience more productivity than ever before. We provide hardware and software so your team can focus on what matters.',
          icon: 'info-circle'
        },
        {
          title: 'Networking',
          description:
            'We help identify and resolve bottlenecks in your network. With a thorough audit of your current network status, we can provide you recommendations and tools to get the most of your network. We have access to the best tools in the industry to ensure your network performs at enterprise levels.',
          icon: 'diagram3'
        },
        {
          title: 'Resiliency',
          description:
            'Being ready for unexpected events will ensure minimal downtime if they occur. We look for areas where critical faults may arise and provide insight on how to mitigate the risks. Additional proactive maintenance is also available to reduce the likelihood of catastrophic failures.',
          icon: 'link'
        },
        {
          title: 'Security',
          description:
            'A good security strategy is paramount to every organization. We will assess and develop a security strategy for your organization to ensure the best industry practices are observed. Any vulnerabilities found in your workflow will be identified and remedied. We provide security audits that cover everything from online accounts to physical on-site CCTV.',
          icon: 'shield-check'
        },
        {
          title: 'Storage Solutions',
          description:
            'We have experince in both on and off site storage soltions. We will find the best solution to meet you needs. We can also provide solutions to automate routine backups of your critical data.',
          icon: 'hdd-network'
        },
        {
          title: 'Remote work',
          description:
            "Every day, more organizations are shifting to remote work. We will help develop the best strategy for working remotely and provide the tools to ensure you don't lose efficiency and productivity when your team works from home.",
          icon: 'globe'
        },
        {
          title: 'Savings',
          description:
            'Our products and rates are very competitive, and we provide enterprise-level solutions at a cost that will meet your budget. We also help you determine if additional savings and be found in your current IT infrastructure and services.',
          icon: 'calculator'
        }
      ]
    }
  },
  methods: {
    isOdd(num) {
      return num % 2 === 0 ? true : false
    }
  }
}
</script>

<style scoped>
h1 {
  font-weight: bolder;
}
h2 {
  font-weight: bold;
}
</style>
